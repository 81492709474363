h1, h2, h3, h4, h5, h6 {
  color: var(--base-font-color);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-weight: 600;
  font-size: 16px;
}

h5 {
  font-weight: 600;
  font-size: 14px;
}

p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.text-black {
  color: var(--black);
}

.text-blue {
  color: var(--blue);
}

.text-green {
  color: var(--green);
}

.text-blue-dark {
  color: var(--base-font-color);
}

.text-red {
  color: var(--red) !important;
}

.text-orange {
  color: var(--orange) !important;
}

.text-muted {
  color: #999;
}

.fw-bolder {
  font-weight: 800;
}

.fw-bold {
  font-weight: 700;
}

.bg-blue {
  background: var(--blue);
}

.bg-color {
  background: var(--background-color);
  --background: var(--background-color);
}

.bg-blue-lighter {
  background: var(--blue-lighter);
}

.bg-blue-pastel {
  background: var(--blue-pastel);
}

.bg-green {
  background: var(--green);
}

.bg-white {
  background: var(--white);
  --background: var(--white);
}

.bg-red {
  background: var(--red);
}

.clickable {
  cursor: pointer;
}

.explain {
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0 20px 0;
}

$icomoon-font-family: "coss" !default;
$icomoon-font-path: "~/src/assets/fonts/coss/fonts" !default;

$coss-redo: "\e90e";
$coss-wallet: "\e908";
$coss-trash: "\e909";
$coss-copy: "\e90a";
$coss-pencil: "\e90b";
$coss-share: "\e90c";
$coss-list-add: "\e90d";
$coss-clapping: "\e910";
$coss-coffee-cup: "\e911";
$coss-arrow-left: "\e907";
$coss-clock: "\e905";
$coss-cup: "\e906";
$coss-badge: "\e900";
$coss-challenge: "\e901";
$coss-feedback: "\e902";
$coss-home: "\e903";
$coss-user: "\e904";


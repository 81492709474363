

.challenge-question {
  ion-item {
    --padding-start: 0;
    --min-height: 35px;
  }
  app-challenge-question-select {
    width: 150px;
    margin-right: 10px;
  }
  .challenge-question-holes {
    line-height: 44px;
  }
  .challenge-question-input {
    display: inline-flex;
    background:var(--white);
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    outline: none;
    border-bottom: 2px solid var(--green);
    width: 150px;
    height: 35px;
    color: var(--green);
    font-weight: 500;
    margin: 0 10px;
    padding: 0;
    font-size: 14px;
  }
  .challenge-question-input-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .challenge-question-input-prefix {
    display: inline-flex;
    width: 20px;
  }
  ion-select {
    --placeholder-color: var(--green);
  }
}

.select-container {
  z-index: 903;
}

::ng-deep {
  .challenge-question-input ion-select {
    height: 35px !important;
    min-height: 35px !important;
    width: 100% !important;
    max-width: 100% !important;
    .native-wrapper {
      width: 100% !important;
      height: 35px !important;
    }
  }
}

ion-item {
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background: transparent;
  &.ion-activated {
  }
}

ion-checkbox {
  --border-color: var(--dark-grey);
  --checkmark-color: var(--base-font-color);
  --checkbox-background-checked: var(--green);
  --border-color-checked: var(--green);
}

ion-radio {
  --color-checked: var(--green);
}

input.form-control {
  width: 100%;
  border: none;
  border-radius: 20px;
  background: var(--grey);
  box-shadow: none;
  outline: none;
  color: var(--input-color);
  font-weight: 500;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  ::placeholder {
    color: var(--input-placeholder);
    opacity: 1;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

textarea.form-control {
  width: 100%;
  border: none;
  border-radius: 20px;
  background: var(--grey);
  box-shadow: none;
  outline: none;
  color: var(--input-color);
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  ::placeholder {
    color: var(--input-placeholder);
    opacity: 1;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

.input-group {
  width: 100%;
  border: none;
  border-radius: 20px;
  background: var(--grey);
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ion-icon {
    color: #999;
  }
  input.form-control {
    background: transparent;
  }
}

// Create a map with `(school: (color: hex))` pairs
$schools: (
        'wsf': ('color': #FF5C1C),
        'coss': ('color': #0056ED),
);

// Define a mixin to generate the classes
@mixin generate-school-classes($name, $properties) {
  .text-#{$name} {
    color: map-get($properties, 'color');
  }
  .bg-#{$name} {
    background-color: map-get($properties, 'color');
  }
}

// Loop through the map and generate the classes
@each $name, $properties in $schools {
  @include generate-school-classes($name, $properties);
}

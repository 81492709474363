@import '../assets/fonts/coss/style';

@font-face {
  font-family: 'Red Hat Display';
  src: url('~src/assets/fonts/RedHatDisplay-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-display: block;
}

:root,
:root[mode='md'],
:root[mode='ios'] {
  --ion-font-family: 'Red Hat Display', sans-serif;
  font-family: var(--ion-font-family) !important;
}

.card {
  background:var(--white);
  position: relative;
  z-index: 901;
  border-radius: 20px;
  box-shadow: 0 0 20px 5px  var(--shadow-color);
  padding: 10px;
  width: 100%;
  &.blue {
    background: var(--blue-lighter);
  }
  &.yellow {
    background: var(--yellow-lighter);
  }
  &.green {
    background: var(--green-lighter);
  }
  &.shadow-sm {
    box-shadow: 0 3px 6px  var(--shadow-color);
  }
  &.shadow-lg {
    box-shadow: 0 0 20px 5px  var(--shadow-color);
  }
  .card-main-img {
    width: auto;
    height: 75px;
    margin: auto;
    display: block;
  }
  &.challenge-card {
    min-height: 75px;
  }
  &.challenge-card-big, &.challenge-card-medium {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-repeat: no-repeat;
    background-position: center 70%;
    background-size: 150%;
    transition: transform 0.3s ease-in-out;
  }

  &.challenge-card-big {
    min-height: 240px;
  }
  &.challenge-card-medium {
    min-height: 150px;
  }
}

.card .icon-card {
  width: 150px;
  height: auto;
  &.m-auto {
    display: block;
    margin: auto;
  }
  &.xs {
    width: 30px;
  }
  &.sm {
    width: 50px;
  }
  &.md {
    width: 75px;
  }
  &.lg {
    width: 100px;
  }
}

.skill-image {
  width: auto;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &.lg {
    height: 150px;
  }
  &.md {
    height: 80px;
  }
  &.sm {
    height: 50px;
  }
}

.stat-pill {
  font-size: 12px;
  font-weight: bold;
  color: var(--blue);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: var(--blue-lighter);
}


// If dark mode, change the border color
:root.dark-theme {
  .card {
    border: 1px solid #444 !important;
  }
}

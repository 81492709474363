ion-modal.modal-auto-height {
  --width: 90%;
  --min-heigh: none;
  --height: auto;
  --max-height: 90%;
  --max-width: 350px;
  --border-radius: 20px;
  --box-shadow: 0 0 20px 0 var(--shadow-color) !important;
  --border-color: var(--modal-border-color);
  --border-width: 1px;
  --border-style: solid;
  .container {
    height: 100%;
    overflow-y: auto;
    max-width: none;
    margin: 0;
    padding: 30px;
  }
}

// Onboarding modal
ion-modal.onboarding-modal {
  --width: 100%;
  --height: 100%;
  --max-width: 100%;
  --max-height: 100%;
  --border-radius: 0;
}

// On large screen, increase the width of the modal
@media (min-width: 800px) {
  ion-modal.modal-auto-height {
    --max-width: 500px;
    .container {
      margin: 0;
    }
  }
  // Not onboarding modal
  ion-modal:not(.onboarding-modal) {
    .container {
      height: 100%;
      overflow-y: auto;
      max-width: none;
      margin: 0;
      padding: 30px;
    }
  }
}

// Add padding for the safe area
ion-modal > .ion-page > ion-content {
  --padding-top: var(--ion-safe-area-top, 0px);
  --padding-bottom: var(--ion-safe-area-bottom, 0px);
}

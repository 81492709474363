.btn {
  width: auto;
  height: 40px;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  &[disabled], &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.btn-block {
    display: flex;
    width: 100%;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
  &.btn-rounded {
    border-radius: 20px;
  }

  &.btn-green {
    background: var(--green);
    color:var(--white);
  }
  &.btn-red {
    background: var(--ion-color-danger);
    color:var(--white);
  }
  &.btn-white {
    background:var(--white);
    color: var(--green);
  }

  &.btn-sm.btn-rounded {
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  ion-icon {
    font-size: 16px;
  }

  &.btn-rounded {
    border-radius: 20px;
  }

  &.btn-outlined {
    border: 1px solid;
    &.btn-green {
      border-color: var(--green);
      color: var(--green);
      background: transparent;
    }
    &.btn-grey {
      border-color: var(--muted);
      color: var(--muted);
      background: transparent;
    }
  }
  &.btn-link {
    background: transparent;
    border-bottom: 2px solid;
    padding: 0;
    margin: 0;
    &.btn-green {
      background: transparent;
      border-color: var(--green) !important;
      color: var(--green) !important;
    }
  }
  &.btn-fixed-bottom[slot='fixed'] {
    bottom: 20px;
    right: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }
}

.tab-selector {
  z-index: 2;
  width: 100%;
  position: relative;
  overflow: visible;
  overflow-x: auto;
  .tab-selector-inner {
    display: flex;
    width: fit-content;
    min-width: 100%;
    background: var(--white);
    padding: 3px;
    box-shadow: 0 0 10px 0  var(--shadow-color);
    border-radius: 50px;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    flex-wrap: nowrap;
  }
  button {
    width: 100%;
    background: var(--white);
    color: var(--green);
    justify-content: center;
    padding: 10px 20px !important;
    white-space: nowrap;
    margin: 0;
    &.active {
      font-weight: bold;
      background: var(--green);
      color:var(--white);
    }
  }
}

@media (min-width: 800px) {
  .btn-fixed-bottom[slot='fixed'] {
    width: auto !important;
    height: auto !important;
    max-width: 400px;
    // center on x-axis
    left: 50% !important;
    right: auto !important;
    padding: 15px 40px;
    transform: translateX(-50%) !important;
  }
}

:root.dark-theme {
  .tab-selector-inner {
    border: 1px solid var(--border-color);
  }
}

@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?hojo8v') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hojo8v') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hojo8v') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hojo8v##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.coss-redo {
  &:before {
    content: $coss-redo; 
  }
}
.coss-wallet {
  &:before {
    content: $coss-wallet; 
  }
}
.coss-trash {
  &:before {
    content: $coss-trash; 
  }
}
.coss-copy {
  &:before {
    content: $coss-copy; 
  }
}
.coss-pencil {
  &:before {
    content: $coss-pencil; 
  }
}
.coss-share {
  &:before {
    content: $coss-share; 
  }
}
.coss-list-add {
  &:before {
    content: $coss-list-add; 
  }
}
.coss-clapping {
  &:before {
    content: $coss-clapping; 
  }
}
.coss-coffee-cup {
  &:before {
    content: $coss-coffee-cup; 
  }
}
.coss-arrow-left {
  &:before {
    content: $coss-arrow-left; 
  }
}
.coss-clock {
  &:before {
    content: $coss-clock; 
  }
}
.coss-cup {
  &:before {
    content: $coss-cup; 
  }
}
.coss-badge {
  &:before {
    content: $coss-badge; 
  }
}
.coss-challenge {
  &:before {
    content: $coss-challenge; 
  }
}
.coss-feedback {
  &:before {
    content: $coss-feedback; 
  }
}
.coss-home {
  &:before {
    content: $coss-home; 
  }
}
.coss-user {
  &:before {
    content: $coss-user; 
  }
}


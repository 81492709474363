swiper.scaled {
  .swiper-slide {
    .challenge-card-big, .challenge-card-medium, .card-scaled {
      transition: all 0.3s ease-in-out;
      transform: scale(.9);
    }
    &.swiper-slide-active {
      .challenge-card-big, .challenge-card-medium, .card-scaled {
        transform: scale(1);
      }
    }
  }
}

.swiper-pagination {
  position: relative;
  padding-top: 10px;
  .swiper-pagination-bullet {
    opacity: 1;
    display: inline-flex;
    transition: width 0.3s ease-in-out;
    height: 3px;
    border-radius: 3px !important;
    width: 10px;
    background: #99BBF8;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--blue);
    height: 3px;
    width: 20px;
  }
  &.green {
    .swiper-pagination-bullet {
      background: var(--green);
    }
    .swiper-pagination-bullet-active {
      background: var(--green);
    }
  }
}


.tab-selector.swipered {
  background: transparent !important;
  box-shadow: none !important;
  overflow: visible;
  padding: 0;
  border-radius: 0;
  swiper {
    width: 100%;
    overflow: visible;
    .swiper-wrapper {
      width: fit-content;
      background: var(--white);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
      border-radius: 20px !important;
    }
    .button-container {
      padding: 5px;
      button {
        width: auto;
        padding: 10px 30px !important;
      }
    }
    .swiper-slide {
      width: auto;
    }
  }
}

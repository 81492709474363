/** Ionic CSS Variables **/
:root {
  --ion-toolbar-background: transparent;
  // --ion-toolbar-background: linear-gradient(167deg, var(--ion-color-header-middle),  var(--ion-color-header-end));

  // Breakpoint
  --breakpoint-sm: 800px;

  // Light mode
  --background-color: #f4f5f8;
  --blue-pastel: #B1DAFB;
  --blue-background-highlight: #D8ECFB;
  --grey: #ECEFFA;
  --blue-lighter: #D8ECFB;
  --blue: #0056ED;
  --secondary: #0056ED;
  --blue-light:#2264F6;
  --green: #39AA91;
  --green-lighter: #D7EEE9;
  --base-font-color: #080759;
  --muted: #999;
  --yellow-lighter: #FDEDE0;
  --black: #111;
  --orange: #FF5C1C;
  --red: #F41603;
  --white: #fff;
  --shadow-color: rgba(0, 0, 0, 0.05);
  --border-color: #f0f0f0;
  --modal-border-color: #eee;
  --input-color: #666;
  --input-placeholder: #999;

  // --ion-toolbar-background: linear-gradient(167deg, var(--ion-color-header-middle),  var(--ion-color-header-end));
  --ion-text-color: var(--base-font-color);
  --ion-text-color-rgb: 8,7,89;
  --ion-background-color: var(--background-color);
  --ion-color-primary: var(--green);

  // Dark mode
  &.dark-theme {
    --background-color: #222;
    --blue-background-highlight: #333;
    --blue-pastel: #{darken(#B1DAFB, 80%)};
    --grey: #333;
    --blue-lighter: #{darken(#D8ECFB, 80%)};
    --blue: #{lighten(#0056ED, 40%)};
    --secondary: var(--blue);
    --blue-light:#2264F6;
    --green: #39AA91;
    --green-lighter: #154338;
    --base-font-color: #f0ffff;
    --muted: #999;
    --yellow-lighter: #FDEDE0;
    --black: #eee;
    --orange: #ff8450;
    --red: #F41603;
    --white: #202020;
    --shadow-color: rgba(125, 125, 125, 0.05);
    --ion-background-color: #222;
    --ion-overlay-background-color: #222;
    --border-color: #444;
    --modal-border-color: #444;
    --input-color: #eee;
    --input-placeholder: #999;
  }

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** light item background **/
  --ion-color-bg-light: #ffffff;
  --ion-color-bg-gray-light: #f2f2f2;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #eeeeee;
  --ion-color-white-tint: #ffffff;

}

// define a map of sizeName => size (increment of 10px)
$sizes: (0: 0, '05': 5px, '1': 10px, '15': 15px, '2': 20px, '3': 30px, '4': 40px, '5': 50px);

// Define a mixin to generate the classes
@mixin generate-size-classes($name, $size) {
  .py-#{$name} {
    padding-top: $size;
    padding-bottom: $size;
  }
  .px-#{$name} {
    padding-left: $size;
    padding-right: $size;
  }
  .pt-#{$name} {
    padding-top: $size;
  }
  .pb-#{$name} {
    padding-bottom: $size;
  }
  .ps-#{$name} {
    padding-left: $size;
  }
  .pe-#{$name} {
    padding-right: $size;
  }
  .mt-#{$name} {
    margin-top: $size;
  }
  .mb-#{$name} {
    margin-bottom: $size;
  }
  .ms-#{$name} {
    margin-left: $size;
  }
  .me-#{$name} {
    margin-right: $size;
  }
  .my-#{$name} {
    margin-top: $size;
    margin-bottom: $size;
  }
  .mx-#{$name} {
    margin-left: $size;
    margin-right: $size;
  }
  .m-#{$name} {
    margin: $size;
  }

  .p-#{$name} {
    padding: $size;
  }

  .gap-#{$name} {
    gap: $size;
  }

  .w-100 {
    width: 100%;
  }

  .w-50 {
    width: 50%;
  }

  .w-#{$name} {
    width: $size * 10;
  }

  .maxw-#{$name} {
    max-width: $size * 10;
  }

  .minw-#{$name} {
    min-width: $size * 10;
  }
}

// For each sizes defines margins, paddings
@each $name, $size in $sizes {
  @include generate-size-classes($name, $size);
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.rounded {
  border-radius: 20px;
}

.no-shadow {
  box-shadow: none !important;
}

.shadow {
  box-shadow: 0 0 10px 0  var(--shadow-color);
}

.separator {
  height: 10px;
  width: 100%;
  // Background gradient top light gray to transparent
  background: linear-gradient(to bottom, #f5f5f5, rgba(255, 255, 255, 0));
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fs-1 {
  font-size: 32px;
}

.fs-15 {
  font-size: 28px;
}

.fs-2 {
  font-size: 24px;
}

.fs-3 {
  font-size: 20px;
}

.fs-4 {
  font-size: 16px;
}

.fs-5 {
  font-size: 14px;
}

.h-100 {
  height: 100%;
}

.fs-6 {
  font-size: 12px;
}

.fs-7 {
  font-size: 10px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.maw-300 {
  max-width: 300px;
}

.miw-0 {
  min-width: 0;
}

.top-50 {
  top: 50%;
  transform: translateY(-50%);
}

.left-50 {
  left: 50%;
  transform: translateX(-50%);
}

.right-50 {
  right: 50%;
  transform: translateX(50%);
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mh-100 {
  min-height: 100%;
}

.text-break-word {
  word-break: break-word;
}

.gap-10 {
  gap: 10px;
}

.border-0 {
  border: none !important;
}

#app-container {
  display: flex;
  width: 100%;
  height: 100%;
  #left-menu {
    width: 350px;
    min-height: 100vh;
    background: var(--white);
    box-shadow: 0 0 10px 0 var(--shadow-color);
    z-index: 2;
  }
  #router-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    #main-footer {
      display: none;
      width: 100%;
    }
    #router-container {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

// Hide the left menu on small screens
@media (max-width: 800px) {
  #app-container {
    #left-menu {
      display: none;
    }
    #router-wrapper {
      #router-container {
        margin-top: 0;
      }
      #main-footer {
        display: flex;
      }
    }
  }
}

.container {
  padding: 20px;
  overflow: visible;
}

ion-content > .container:first-child {
  padding-top: 20px !important;
}

#menu-content .ion-page > ion-content {
  --padding-top: var(--ion-safe-area-top, 0px);
  background: radial-gradient(at 10% 20px, var(--blue-background-highlight) -5%, var(--background-color) 200px);
  --background: radial-gradient(at 10% 20px , var(--blue-background-highlight) -5%, var(--background-color) 200px);
}

// On larger screen, change the ion-content background position
@media (min-width: 800px) {
  #menu-content .ion-page > ion-content {
    background: radial-gradient(at 25% 20px, var(--blue-background-highlight) -5%, var(--background-color) 250px);
    --background: radial-gradient(at 25% 20px , var(--blue-background-highlight) -5%, var(--background-color) 250px);
  }
}

.page-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background:var(--white);
}

ion-footer {
  box-shadow: 0 0 20px 5px  var(--shadow-color);
  --background:var(--white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ion-toolbar {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    --background:var(--white);
    --border-width: 0 !important;
    --min-height:70px;
    height: auto !important;
  }
}

app-contact-list {
  min-height: 100%;
  width: 100%;
}

// Change container padding on small screens
@media (min-width: 800px) {
  .container {
    padding: 20px 60px;
    margin: 0 auto;
    max-width: 1000px;
  }
}

@media ((min-width: 800px) and (max-width: 1100px)) {
  #app-container {
    #left-menu {
      width: 300px;
    }
  }
}

:root.dark-theme {
  #app-container {
    #left-menu {
      border-right: 1px solid var(--border-color);
    }
  }
}

.full-height {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  min-height: 100%;
  .container {
    width: 100%;
  }
  .page-container {
    width: 100%;
    min-height: 100%;
    flex: 1;
  }
  &.padded .page-container {
    padding-bottom: 70px;
  }
}
